.contact-container {
	background-color: #464646;
	color: white;
	padding-bottom: 50px;
	font-size: 20px;
}

.contact-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-left: 50px;
	padding-right: 50px;
	grid-column-gap: 40px;
	grid-template-areas: "contact-right-text contact-form";
	padding-top: 50px;
	padding-bottom: 30px;
	padding-left: 200px;
	padding-right: 200px;
}

.contact-name {
	width: 100%;
	height: 40px;
	outline: none;
	padding: 5px;
	text-indent: 5px;
	margin-bottom: 30px;
	border-radius: 10px;
	border: none;
	font-size: 18px;
	font-family: "Montserrat";
	font-weight: 600;
	color: rgb(14, 14, 14);
}

.contact-textarea {
	width: 100%;
	outline: none;
	padding: 5px;
	text-indent: 5px;
	margin-bottom: 20px;
	border-radius: 10px;
	border: none;
	font-size: 18px;
	font-family: "Montserrat";
	font-weight: 600;
	color: rgb(14, 14, 14);
}

.contact-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sendBtn {
	width: 140px;
	height: 45px;
	color: #464646;
	font-size: large;
	text-transform: uppercase;
	outline: none;
	border: 2px solid #464646;
	margin-top: 20px;
	align-self: center;
	border-radius: 10px;
	border: none;
	font-family: "Montserrat";
	font-weight: 800;
	cursor: pointer;
}

.contact-right-text {
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-title {
	font-size: 40px;
	text-align: center;
	padding: 2rem;
	margin: 0;
}

.title-bold {
	margin: 5px;
	margin-top: 20px;
	font-family: "Montserrat";
	font-weight: 800;
}

.text-medium {
	font-family: "Montserrat";
	font-weight: 500;
}

.email {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.phone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-left: 20%;
	margin-right: 5%;
}

.text-medium a {
	text-decoration: none;
	color: white;
}

.contact-icon {
	width: 64px;
	height: 64px;
	background-color: #2B2B2B;
	border-radius: 50%;
	outline: none;
	font-size: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}
.icons{
	width: 50px;
  }