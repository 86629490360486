html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: rgb(241, 241, 241);
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

h1 {
  font-weight: 700;
}

p,
a {
  font-family: 'Montserrat', sans-serif;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  transition: all 0.3s ease-in-out;
}

.section-title {
  margin: 0px;
  margin-bottom: 4.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(63, 63, 63);
  margin-right: 2%;
  margin-left: 2%;
}

.nav-links {
  justify-content: space-evenly;
  list-style: none;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 800;
}

.nav-links li,
.active li {
  color: rgb(63, 63, 63);
  text-decoration: none;
  display: inline-block;
  padding: 20px;
}

.menu-icon {
  display: none;
  transition: all .3s ease-in-out;
}

.nav-logo-text {
  justify-content: flex-start;
  list-style: none;
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0;
  color: rgb(14, 14, 14);
}

.nav-hidden {
  position: absolute;
  top: 10;
  z-index: -11;
  width: 100%;
  background-color: rgb(34, 34, 34);
  transition: all .3s ease-in-out;
}

.nav-hidden li {
  color: rgb(255, 255, 255);
}

.active {
  display: none;
  list-style: none;
  font-size: 17px;
  font-family: "Montserrat";
  font-weight: 500;
  text-transform: uppercase;
}

.language {
  width: 30px;
  margin-left: 15px;

  cursor: pointer;
}