.hero-title {
  display: flex;
  margin-left: 9%;
}


.main-title {
  color: rgb(29, 29, 29);
  font-size: 46px;
}

.under-title {
  color: rgb(29, 29, 29);
  font-size: 18px;
  font-weight: 400;
}

.hero-container hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  width: 80%;
  border-top: 2px solid rgba(41, 41, 41, 0.219);
}

canvas {
  display: block;
  vertical-align: bottom;
}

/* ---- particles.js container ---- */

#particles-js {
  position: absolute;
  width: 100%;
  height: 80%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
}

@media (max-width: 480px) {
  .hero-title {
    margin-left: 7%;
  }
}