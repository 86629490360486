.project-container{
    color: rgb(43, 43, 43);
    padding-bottom: 50px;
    font-size: 16px;
  }
.projects-content{
  display: grid;
  margin: 0px 120px 0px 120px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    "tile1 tile1 tile2 tile2 tile3 tile3"
    "tile4 tile4 tile5 tile5 tile6 tile6";

}
.tile1 {
  grid-area: tile1;
}
.tile2 {
  grid-area: tile2;
}
.tile3 {
  grid-area: tile3;
}
.tile4 {
  grid-area: tile4;
}
.tile5 {
  grid-area: tile5;
}
.tile6 {
  grid-area: tile6;
}
.project-tile {

  position: relative;
  text-align: center;
  background-color: grey;
  border-radius: 10px;
  border:none;
  margin: 15px;
  /* -webkit-box-shadow: 5px 5px 13px 8px rgba(0,0,0,0.2); 
  box-shadow: 5px 5px 13px 8px rgba(0,0,0,0.2); */
}
.project-tile .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  border-radius: 10px;
  top: 0;
  left: 0;
  background-color:rgba(0,0,0,0.87);
  color: white;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}

.project-tile:hover .overlay {
opacity: 1;
filter: alpha(opacity=100);
-webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.51); 
box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.51);
}

.project-tile img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.project-tile .overlay hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  width: 40%;
  border-top: 2px solid rgba(160, 84, 34, 0.514);
}

.overlay .contact-icon i{
  font-size: 22px;
}
.overlay  a{
  color: white;
  line-height: 8px;
}
.attachment{
  display: flex;
	justify-content: center;
	align-items: center;
}
.attachment a{
margin:10px;
}