.about-container {
	background-color: white;
	color: #272727;
	font-size: 20px;
	padding-bottom: 30px;
}

.about-content {
	display: flex;
	justify-content: space-between;
	margin: 0px 60px 0px 60px;
}

.about-image {
	width: 30vh;
	margin-left: 5%;
	padding-right: 5%;
	object-fit: contain;
}

.about-text {
	display: flex;
	flex-direction: column;
	margin: 30px 0px 30px 0px;
	font-size: 16px;
	line-height: 1.6;
	text-align: left;
}

.about-other {
	margin: 0;
	margin-top: 2%;
	margin-bottom: 5%;
	font-size: 24px;
	text-align: center;
}

.cvButton {
	width: 180px;
	height: 45px;
	color: white;
	background-color: #363636;
	font-size: large;
	text-transform: uppercase;
	outline: none;
	border: 2px solid #464646;
	margin-top: 80px;
	align-self: center;
	border-radius: 10px;
	border: none;
	font-family: "Montserrat";
	font-weight: 800;
	cursor: pointer;
}

.cvButton a {
	color: white;
}

.cvButton i {
	font-size: 18px;
	margin-right: 10px;
}

.skills {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.skill-item {
	color: #272727;
	font-size: 20px;
	font-family: "Montserrat";
	font-weight: 500;
	text-align: center;
	padding: 0px 30px 0px 30px;
}

.gallery-content {
	display: flex;
	flex-wrap: wrap;
	grid-template-columns: repeat(3, [col-start] fit-content(350px) [col-end]);
	row-gap: 20px;
	column-gap: 40px;
	padding-bottom: 60px;
	margin-top: 50px;
	place-content: center;
	position: relative;
}

.gallery-image {
	height: 60vh;
	width: 85vh;
	object-fit: cover;
	border-radius: 8px;
	pointer-events: auto;
}

.gallery-image-drawing {
	height: 500px;
	width: 350px;
	object-fit: cover;
	border-radius: 8px;
	pointer-events: auto;
}

.about-container hr {
	margin-top: 2rem;
	margin-bottom: 1rem;
	border: 0;
	width: 80%;
	border-top: 1px solid rgba(31, 31, 31, 0.37);
}