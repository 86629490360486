footer {
	background-color: #333333;
	color: #fff;
	padding: 4.8rem 0;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.socials {
	display: flex;
	justify-content: center;
}

.social-links {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	color: white;
	height: 44px;
	width: 44px;
	background-color: #343434;
	border-radius: 8px;
	margin: 20px;
	cursor: pointer;
}

.social-links a {
	text-decoration: none;
	color: white;
}

i {
	font-size: 30px;
	cursor: pointer;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	width: 50%;
	border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.back-to-top {
	margin: 0px 0px 30px 0px;
}